import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';

// API base URL
const API_BASE_URL = 'https://www.mtg-admin.com/api';

function CEDHTournamentStatsNew() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [filterType, setFilterType] = useState('all');

    // Fetch data based on the selected filter
    const fetchData = async () => {
        setLoading(true);
        let url = `${API_BASE_URL}/standings`;

        if (filterType === 'player' && searchQuery) {
            url = `${API_BASE_URL}/standings/players?players=${searchQuery}`;
        } else if (filterType === 'commander' && searchQuery) {
            const commanders = searchQuery.split(',').map(cmd => cmd.trim());
            url = `${API_BASE_URL}/standings/commanders?commander1=${commanders[0]}&commander2=${commanders[1] || ''}`;
        }

        try {
            const response = await fetch(url);
            const result = await response.json();
            setData(result);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [filterType]);

    const handleSearch = (e) => {
        e.preventDefault();
        fetchData();
    };

    const columns = React.useMemo(
        () => [
            { Header: 'Player', accessor: 'playerName' },
            { Header: 'Commander 1', accessor: 'commander1' },
            { Header: 'Commander 2', accessor: 'commander2' },
            { Header: 'Standing', accessor: 'standing' },
            { Header: 'Tournament', accessor: 'tournamentName' },
            { Header: 'Wins', accessor: 'wins' },
            { Header: 'Losses', accessor: 'losses' },
            { Header: 'Draws', accessor: 'draws' },
            { Header: 'Player Count', accessor: 'playerCount' },
            { Header: 'Decklist URL', accessor: 'decklistUrl' },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    if (loading) return <div>Loading...</div>;

    return (
        <div>
            <form onSubmit={handleSearch}>
                <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    style={{ marginRight: '10px' }}
                />
                <select onChange={(e) => setFilterType(e.target.value)} value={filterType}>
                    <option value="all">All</option>
                    <option value="player">Search by Player</option>
                    <option value="commander">Search by Commander</option>
                </select>
                <button type="submit">Search</button>
            </form>

            <table {...getTableProps()} style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} key={column.id} style={{ border: '1px solid black', padding: '8px' }}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} key={row.id}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()} key={cell.column.id} style={{ border: '1px solid black', padding: '8px' }}>
                                        {cell.render('Cell')}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default CEDHTournamentStatsNew;
